import React from "react";
import { Helmet } from "react-helmet";
import StandardLayout from "../layout/StandardLayout";

const About = () => {
  return (
    <StandardLayout>
      <Helmet>
        <title>About Us - Burke Shipping Services</title>
      </Helmet>

      <div
        className="c-hero"
        style={{ backgroundImage: "url(/images/shipping-hero-2.jpg)" }}
      ></div>

      <div className="u-fill-1 u-pad-large u-pad-xlarge@m">
        <div className="l-wrap u-width-5 u-align-center u-marg-bottom u-marg-bottom-large@m">
          <h1 className="c-heading +h1 u-marg-bottom">About</h1>
          <p className="u-font-large">
            A division of Fane Valley Co-operative, Burke Shipping Services Ltd
            has been operating in the Port of Londonderry for over 50 years.
            Located in the North West of Ireland, at the head of Lough Foyle, a
            sheet of almost landlocked water 26km long and 13km wide.
          </p>
        </div>
        <div className="l-wrap">
          <div className="l-grid +cols-1 +cols-3@m +gap-3">
            <div className="l-grid__item u-pad-top-bottom u-align-center u-marg-top@m">
              <h3 className="c-heading +h3 u-marg-bottom">Location</h3>
              <p>
                Lough Foyle forms an excellent sheltered deep-water harbour,
                which is accessible at all states of the tide and in all weather
                conditions.
              </p>
            </div>

            <div className="l-grid__item u-pad-top-bottom u-align-center u-marg-top@m">
              <h3 className="c-heading +h3 u-marg-bottom">Experienced Staff</h3>
              <p>
                Our experienced staff have an in-depth knowledge of the port,
                providing a range of services including ships agency,
                stevedoring, warehousing, haulage and customs clearance to meet
                our customer’s needs.
              </p>
            </div>

            <div className="l-grid__item u-pad-top-bottom u-align-center u-marg-top@m">
              <h3 className="c-heading +h3 u-marg-bottom">Certifications</h3>
              <p>
                Burke Shipping Services Ltd operates the highest quality levels
                to ensure excellence in customer service. Our Ship Agency,
                Stevedoring and Warehousing operations are all certified to ISO
                9001:2015, a copy of the certificate can be found{" "}
                <a
                  href="/files/Certificate of Registration 13.09.19.pdf"
                  target="_blank"
                >
                  here
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="c-media-text +background-image-fill">
        <div className="l-wrap">
          <div className="c-media-text__contents">
            <div className="c-media-text__a">
              <div>
                <h3 className="c-heading +h3 u-marg-bottom">
                  Port Information
                </h3>
                <p className="u-marg-bottom-large">
                  The following restrictions apply to vessels arriving /
                  departing Londonderry Port -Lisahally Terminal.
                </p>

                <p className="u-font-bold u-marg-bottom">
                  Vessels less than 27,000 DWT
                </p>
                <ul className="u-marg-bottom">
                  <li>Max LOA: 200m</li>
                  <li>Max Beam: 26m</li>
                  <li>
                    Max Draft: 9.30m (Subject to Height of Tide, recommended
                    berthing daylight only)
                  </li>
                  <li>Max Air Draft: 40.57 above MHWS (2.50m High Tide)</li>
                </ul>

                <p className="u-font-bold u-marg-bottom">
                  Vessels between 27,000 DWT and 40,000 DWT
                </p>
                <ul className="u-marg-bottom">
                  <li>Max LOA: 200m</li>
                  <li>Max Beam: 30m</li>
                  <li>
                    Max Draft: 7.50m (Berthing/Sailing at High Water/Day Light
                    only)
                  </li>
                  <li>Max Air Draft: 40.57m above MHWS (2.50m High Tide)</li>
                </ul>

                <p className="u-font-bold u-marg-bottom">
                  Vessels over 40,000 DWT
                </p>
                <ul className="u-marg-bottom">
                  <li>Max LOA: 200m</li>
                  <li>Max Beam: 32m</li>
                  <li>
                    Max Draft: 7.00m (Berthing/Sailing at High Water/Day Light
                    only)
                  </li>
                  <li>Max Air Draft: 40.57m above MHWS (2.50m High Tide)</li>
                </ul>

                <p className="u-font-bold u-marg-bottom">
                  Moville Bay Anchorage
                </p>
                <ul className="u-marg-bottom">
                  <li>Max Draft: 11.50m (HW arrival)</li>
                  <li>Max Air Draft: No Restriction</li>
                </ul>
              </div>
            </div>

            <div className="c-media-text__b">
              <div className="c-media-text__media">
                <img src="/images/about-2.jpg" alt="Port Crane Photo" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="c-splitter"></div>
    </StandardLayout>
  );
};

export default About;
